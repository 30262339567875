import React from 'react';

const ProductTypes = () => {
  return (
    <div>
      <h4>Danh mục</h4>
    </div>
  );
};

export default ProductTypes;
