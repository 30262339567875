import React from 'react';
import styled from 'styled-components';
import { flattenStrapiData } from '../utils/formatter';
import SiteLayout from '../components/SiteLayout';
import { BannerText, Breadcrum } from '../components/common';
import Products from '../components/page/ProductTypePage/Products';
import ProductTypes from '../components/page/ProductTypePage/ProductTypes';

const ProductTypeWrapper = styled.div`
  margin-top: 88px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    width: 100%;
    display: flex;
  }
`;

const ProductType = ({
  data: { strapiProductType: productTypeDetail, allStrapiProductType: productTypes, allStrapiProduct: relatedProducts },
}) => {
  const { name, url } = productTypeDetail;
  const LAYOUT_KEY = 'PRODUCT';

  const breadcrumTree = [
    {
      id: 0,
      name: 'Trang chủ',
      slug: '/',
    },
    {
      id: 1,
      name: 'Sản phẩm',
      slug: '/product',
    },
    {
      id: 2,
      name: name,
      slug: `/${url}`,
    },
  ];

  return (
    <SiteLayout activeKey={LAYOUT_KEY}>
      <ProductTypeWrapper className="background_color_theme">
        <BannerText title="Sản phẩm" />
        <div className="content">
          <ProductTypes />
          <div>
            <Breadcrum breadcrumTree={breadcrumTree} />
            <Products relatedProducts={flattenStrapiData(relatedProducts)} productTypeSlug={url} />
          </div>
        </div>
      </ProductTypeWrapper>
    </SiteLayout>
  );
};

ProductType.propTypes = {};

export default ProductType;

export const query = graphql`
  query($id: String, $product_type_id: String) {
    strapiProductType(id: { eq: $id }) {
      id
      name
      url
      description
      producttype_images {
        url
      }
      producttype_thumbnail {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
    allStrapiProduct(filter: { product_type: { id: { eq: $product_type_id } } }) {
      edges {
        node {
          id
          name
          subname
          unit_price
          discounted_price
          slug
          product_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
    allStrapiProductType(filter: { id: { ne: $id } }) {
      edges {
        node {
          id
          name
          description
          url
          producttype_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
